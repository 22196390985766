<template>
  <div class="flex flex-col gap-2">
    <VInput v-bind="inputProps" ref="input" v-model="modelValue" :validator="validator" validator-mode="force" :type="inputReadable ? 'text' : 'password'" data-component-name="VPassword">
      <template #suffix>
        <VButton icon color="secondary" variant="text" :title="inputReadable ? localize('hide_password') : localize('show_password')" @click="inputReadable = !inputReadable">
          <VIcon :name="inputReadable ? 'Outline/eye-off' : 'Outline/eye'" />
        </VButton>
      </template>
    </VInput>
    <div v-if="showRequirements" role="region" aria-live="polite" class="flex flex-col space-y-1">
      <VSpan size="sm" color="neutral/950" class="self-start">{{ globalLocalize('component-library.validations.password.requirements.label') }}</VSpan>
      <ul class="text-left pl-2">
        <li v-for="(requirement, index) in requirements" :key="index" class="flex items-center gap-2">
          <VSpan size="sm" :color="requirement.isValid ? 'green/500': 'neutral'">- {{ requirement.text }}</VSpan>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, useTemplateRef } from 'vue';
import VInput from './VInput.vue';
import VButton from '../buttons/VButton.vue';
import VIcon from '../labels/VIcon.vue';
import VSpan from '../labels/VSpan.vue';
import type { AutocompleteProps, LengthRestrictionProps, SharedProps, ValidationProps } from './helpers/types';
import { composeValidations, createDefaultPasswordValidator, usePasswordRequirementsValidation } from './helpers/validations';
import { useFallthroughProps } from './helpers/fallthrough';
import { globalLocalize } from '@component-utils/localization';
import { useLocalize } from '@component-utils/localization';

const localize = useLocalize('component-library.inputs.password')

defineOptions({
  name: 'VPassword',
  inheritAttrs: false
})

const props = defineProps<SharedProps & ValidationProps<string> & AutocompleteProps & LengthRestrictionProps & {
  placeholder?: string
  focus?: boolean
  buffered?: boolean
  centered?: boolean
  required?: boolean
  showRequirements?: boolean
}>()

const inputProps = useFallthroughProps(props)

const modelValue = defineModel<undefined | null | string>({ required: true })

const validator = composeValidations(props, createDefaultPasswordValidator(props))

const inputReadable = ref(false)

const inputElement = useTemplateRef('input')

const { requirements, requirementsValid } = usePasswordRequirementsValidation(modelValue)

defineExpose({
  focus: () => {
    inputElement.value?.focus()
  },
  get isValid () {
    return (inputElement.value?.isValid ?? false) && (!props.showRequirements || requirementsValid.value)
  }
})
</script>