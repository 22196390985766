<template>
  <VInput v-bind="inputProps" ref="input" v-model="modelValue" :validator="validator" validator-mode="force" data-component-name="VEmail" />
</template>
<script lang="ts" setup>
import VInput from './VInput.vue';
import type { AutocompleteProps, LengthRestrictionProps, SharedProps, ValidationProps } from './helpers/types';
import { useTemplateRef } from 'vue';
import { composeValidations, createDefaultEmailValidator } from './helpers/validations';
import { useFallthroughProps } from './helpers/fallthrough';

defineOptions({
  name: 'VEmail',
  inheritAttrs: false
})

const props = defineProps<SharedProps & ValidationProps<string> & AutocompleteProps & LengthRestrictionProps & {
  placeholder?: string
  focus?: boolean
  buffered?: boolean
  centered?: boolean
  required?: boolean
}>()

const inputProps = useFallthroughProps(props)

const modelValue = defineModel<undefined | null | string>({ required: true })

const validator = composeValidations(props, createDefaultEmailValidator(props))

const inputElement = useTemplateRef('input')

defineExpose({
  focus: () => {
    inputElement.value?.focus()
  },
  get isValid () {
    return inputElement.value?.isValid ?? false
  }
})
</script>
