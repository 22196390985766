<template>
  <VDialog :icon="props.icon" :title="props.title">
    <VParagraph v-a11y:dialog:description color="neutral/500" class="text-center" v-html="props.message" />

    <div v-if="props.fields.length > 0" class="my-4 flex flex-col gap-2">
      <div v-for="(field, idx) in props.fields" :key="idx">
        <component 
          :is="getComponentForFieldType(field.type)"
          v-model="fieldValues[idx]" 
          v-bind="getFieldProps(field)"
          :focus="idx === 0"
        />
      </div>
    </div>
    
    <template #buttons>
      <VButton v-for="(button, buttonIndex) of buttons" :key="buttonIndex" size="sm" :variant="button.variant" :color="button.color" @click="close(button.value)">
        {{ button.label }}
      </VButton>
    </template>
  </VDialog>
</template>
<script lang="ts" setup>
import VDialog from './VDialog.vue'
import VButton from '@component-library/buttons/VButton.vue';
import VInput from '@component-library/inputs/VInput.vue';
import VNumber from '@component-library/inputs/VNumber.vue';
import VPassword from '@component-library/inputs/VPassword.vue';
import VEmail from '@component-library/inputs/VEmail.vue';
import type { Icon } from '@icons/index';
import { computed, ref, type Component } from 'vue';
import type { V } from '@component-utils/types';
import VParagraph from '@component-library/labels/VParagraph.vue';
import { useLocalize } from '@component-utils/localization';
import { vA11y } from '@component-library/directives';
import type { DialogField } from '@component-utils/dialogs';

const localize = useLocalize('component-library.dialogs')

defineOptions({
  name: 'VSimpleDialog'
})

const props = withDefaults(
  defineProps<{
    message: string
    icon?: Icon
    title?: string
    buttons?: (keyof typeof PREDEFINED_BUTTONS | V.SimpleDialog.Button)[]
    fields?: DialogField[]
  }>(),
  {
    icon: undefined,
    title: undefined,
    buttons: () => ['close'],
    fields: () => []
  }
)

const fieldValues = ref<(string | number | null)[]>(
  props.fields?.map(field => field.default ?? '') ?? []
)

const emit = defineEmits<{
  close: [V.SimpleDialog.Button['value'], fields?: (string | number | null)[]]
}>()

const getComponentForFieldType = (type: string): Component => {
  const componentMap: Record<string, Component> = {
    'text': VInput,
    'number': VNumber,
    'password': VPassword,
    'email': VEmail
  }
  
  if (type in componentMap) {
    return componentMap[type]
  }

  return VInput
}

const getFieldProps = (field: DialogField) => {
  const { type, default: defaultValue, ...props } = field
  return props
}

const close = (value: V.SimpleDialog.Button['value']) => {
  emit('close', value, fieldValues.value)
}

const buttons = computed(() => props.buttons.map((button) => typeof button === 'string' ? PREDEFINED_BUTTONS[button] : button))

const PREDEFINED_BUTTONS: Record<string, V.SimpleDialog.Button> = {
  'close': {
    value: false,
    label: localize('default_close_label'),
    variant: 'outlined',
    color: 'secondary'
  },
  'continue': {
    value: true,
    label: localize('default_continue_label'),
    variant: 'contained',
    color: 'primary'
  },
  'no': {
    value: false,
    label: localize('default_no_label'),
    variant: 'outlined',
    color: 'secondary'
  },
  'ok': {
    value: true,
    label: localize('default_ok_label'),
    variant: 'contained',
    color: 'primary'
  },
  'confirm': {
    value: true,
    label: localize('default_confirm_label'),
    variant: 'contained',
    color: 'primary'
  },
  'cancel': {
    value: false,
    label: localize('default_cancel_label'),
    variant: 'outlined',
    color: 'secondary'
  }
}
</script>